///color
@green:#629509;
@green01:#629515;
@green02:#6b9b21;
@green03:#83ac4e;
@lignt-green:#f5f4de;
@lignt-green01:#d9d7ad;
@lignt-green02:#c6d16c;
@lignt-green03:#dde8cf;
@lignt-yerrow:#fbf8ed;
@lignt-yerrow01:#f8edd1;
@dark-green:#3e6101;
@dark-green01:#517b07;
@black:#303030;
@black01:#2f1d01;
@black02:#3e3e3e;
@black03:#666;
@black04:#4b4b4b;
@dark-glay:#686868;
@glay:#7b776d;
@glay01:#b7b7b7;
@glay02:#b0b0b0;
@lignt-glay:#f9f8f1;
@lignt-glay01:#efefef;
@lignt-glay02:#f9f9f9;
@lignt-glay03:#e0e0e0;
@lignt-glay04:#afada5;
@beige:#64420b;
@red:#f50000;

//site
@siteColor:@green;
@siteBgColor:@white;
@siteTxt:@black;

//@siteEntryBg:@white;
@sitePointColor:@siteColor;
@sitePointColor-Lignt:@lignt-green;
//@txtColor:((@black)+53);//#353535;
@iconColor:@siteColor;
@arrowColor:@green01;
@listTxtColor:@arrowColor;

//globalMenu
@gmenuBgColor:@green02;
//@gmenuBodyBorderColor:@sitePointColor;
@gmenuBorderColor:@green03;
@gmenuTxtColor:@white;
@gmenuTxtColorOn:@sitePointColor;
@gmenuCurrentBorder:@dark-green;
@gmenuCurrentBg:@white;

//new
@newIconBg:@siteColor;
@newIconTxt:@white;

//banLink
@banTxtColor:@white;
@banColor:@sitePointColor;
@banTxtColorOf:@white;
@banColorOf:@white;
//@banIconColor:@iconColor;

//more
@moreArrow:@siteColor;

//contacte
@contactTxtColor:@black01;
@contactElementBorder:@lignt-green;
@contactElementTxtColor:@siteColor;
@contactNoTxtColor:@sitePointColor;
@contactNoTitleColor:@beige;
@contactElementBg:@beige;
@contactePageTable:@lignt-green01;

//concept
@conceptElementBorder:@sitePointColor-Lignt;
@conceptElementBg:@white;
@conceptElementTitle:@sitePointColor;
@conceptElementTxt:@black03;


//breadcrumbs
@breadcrumbsHome:@sitePointColor;
//@breadcrumbsLinkBg:@sitePointColor;
@breadcrumbsLinkTxt:@sitePointColor;
@breadcrumbsNormalTxt:@dark-glay;

//moduletitle
@moduletitleBg: transparent;
@moduletitleTxtColor: @sitePointColor;
@moduletitleBorder:@sitePointColor;

//list
@listBorderColor:@lignt-green03;
@blocktitleBorderColor:@sitePointColor;
@blocktitleTxt:@sitePointColor;
@sideBlocktitleTxt:@white;
@sideBlocktitleBorderColor:@dark-green01;

@catetitleBorderColor:@lignt-green03;
@catetitleBgColor:@sitePointColor;
@catetitleTxt:@sitePointColor;

@listEntryTxt:@black03;
@listiconTxt:@white;
@listiconBg:@siteColor;

@catetitleBg: @white;
@catetitleBgHover:@catetitleBorderColor;
@cateListBorder:@sitePointColor;
@cateListBg:@lignt-green;
@dateTxt:@glay;
//@listTxtColor
@listTitleTxt:@sitePointColor;
@listBlockOver:@lignt-glay;


//sideBlock
@sideBlockTitleBg:@sitePointColor;
@sideBlockTitleTxt:@white;
@sideBlockTitleBorder:@dark-green01;
//@sideBlockTitleTxtShadow:0px 1px 4px rgba(9, 2, 4, 0.4);

//paging
@pagingColor:@sitePointColor;
@pagingTotalpages:@black04;
@pagingTxt:@white;
@pagingColorOf:@white;
@pagingTxtOf:@sitePointColor;
@pagingIcon:@green01;
@pagingColorDisable:@lignt-glay01;
@pagingColorDisableTxt:@glay01;
@pageNavColorBtnBg:@sitePointColor;
@pageNavColorBtnBgOf:@sitePointColor-Lignt;

//mainImage
@mainImgBgColor:@sitePointColor-Lignt;

//detail
@detailTitle:@sitePointColor;
@detailCatch:@beige;
@detailEntryTxt:@black03;

//cart
@cartPriceTxt:@red;
@cartPrice2Txt:@black;
@cartInputBtnBg:@sitePointColor;
@cartInputTableThBg:@lignt-green;
@cartInquiryBg:@lignt-glay02;
@cartInquiryBorder:@glay02;
@cartInquiryTxt:@black02;
@cartInquiryFormboxBorder:@white;
@cartInquiryFormboxBorder2:@lignt-yerrow;
@cartInquiryFormboxBg:@lignt-yerrow01;
@cartInquiryFormboxTxt:@beige;
@cartGuideLinkborder:@lignt-glay03;
@cartGuideLinkTxt:@black02;
@cartGuideLinkTxtOn:@lignt-glay02;
@cartDetailBlockBg:@white;
@cartStepprogre:@lignt-green02;
@cartStepprogreBorder:@sitePointColor;
@neederror:@red;

//footer
@pagetopBtnBg:@sitePointColor-Lignt;
@pagetopIconColor:@sitePointColor;
@footerNavBg:@sitePointColor-Lignt;
@footerSiteName:@sitePointColor;
@footerContactList:@sitePointColor;
//@footerNavBorder:#a47518;
@footerNavTxtColor:@beige;
@footerNavIconColor:@sitePointColor;
@copyBlockBgColor:@sitePointColor;
@copyBlockTxtColor:@white;

//pvSwitch
@pvSwitchBg:@lignt-green;
@pvSwitchTxt:@siteColor;

///siteLayout
@siteW: 960px;

///Mixin
//paging Color（on/off）
.pagingLinkColor(){
	color:@pagingTxt;
	border:1px solid @pagingColor;
	background-color: @pagingColor;
}
.pagingLinkColorOf(){
	color:@pagingTxtOf;
	border:1px solid @pagingColor;
	background-color: @pagingColorOf;
}
.pageNavColor(){
	color:@pagingTxt;
	border:1px solid @pagingColor;
	background-color: @pagingColor;
}
.pageNavColorOf(){
	color:@pagingTxtOf;
	border:1px solid @pageNavColorBtnBgOf;
	background-color: @pageNavColorBtnBgOf;
}

//banner
.banLinkColor(){
	color:@banTxtColor;
	border:1px solid @banColor;
	background-color: @banColor;
}
.banLinkColorOf(){
	color:@sitePointColor;
	border:1px solid @banColor;
	background-color: @banColorOf;
}

//box-shadow
.box-shadow(@box-shadow){
	box-shadow:@box-shadow;
	-webkit-box-shadow:@box-shadow;
	-moz-box-shadow:@box-shadow;
}
//border-radius
.border-radius(@px){
	-moz-border-radius:@px;
	-webkit-border-radius:@px;
	-o-border-radius: @px;
	-ms-border-radius: @px;
	border-radius:@px;
}
//centerBlock
.centerBlock(@W:@siteW,@marT,@marB){
	width:@W;
	margin:@marT auto @marB;
}
//opacity
.opacity(@o){
	-moz-opacity:@o;
	opacity:@o;
	opacity:@o/100;
	filter: "alpha(opacity=@{o})";
	-ms-filter: "alpha(opacity=@{o})";
}
//hoverlink
.hoverlink(){.opacity(80);}
///font
.font-smoothing-auto(){
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}
//iconFont
.iconFont(@icon,@size){
	font-family: 'hybstemplate';
	content: "@{icon}";
	.font-size(@size);
}

/*103_01_white-green*/
body{background-color: @siteBgColor;}
p{color: @sitePointColor;}
.headerWrapper{
	background-color: @white;
	padding-top: 20px;
}
header{margin-bottom: 15px;}
#allbox{
	border-top: 2px solid @sitePointColor;
}
.containerWrap{padding-bottom: 35px;}



//グローバルメニュー
#gmenu{
	background: url(../images/dottosLine.png) repeat-x bottom left @gmenuBgColor;
	overflow: hidden;
	padding-bottom: 5px;
	ul {
		margin: 0 auto;
		position: relative;
		border: 0 none;
		border-left: 1px solid @gmenuBorderColor;
		&:before{
			content: '';
			position: absolute;
			display: block;
			width: 1px;
			height: 80px;height: 8rem;
			top: 0;
			left: 0;
			z-index: 5;
		}
		li {
			margin: 0;
			border: 0 none;
			position: relative;
			width: auto;
			border-right: 1px solid @gmenuBorderColor;
			a{
				.font-size(1.6);
				letter-spacing: 0.1em;
				color: @gmenuTxtColor;
				.font-smoothing-auto;
				background-color: @gmenuBgColor;
				padding-bottom: 30px;
				padding-top: 30px;
				position:relative;
				z-index: 0;
				//.transition(width @transition-medium ease-in-out);
				&:before,&:after{
					content: '';
					display:block;
					width:100%;
					height: 1px;
					position: absolute;
					z-index: 3;
					margin: 0 auto;
					left: 0;
					.transition(width @transition-medium ease-in-out);
				}
				&:before{
					top: 0;
				}
				&:after{
					bottom: -5px;
				}
				&:hover,
				&.current{
					color:@gmenuTxtColorOn;
					background-color: @gmenuCurrentBg;
					&:after{
						border-bottom: 5px solid @gmenuCurrentBg;
					}
					&:before{
						border-top:5px solid @gmenuCurrentBorder;
					}
					&:before,&:after{
					}
				}
				&:hover{
					position: relative;
					z-index:10;
				}
				&.current{
					.box-shadow(2px -2px 32px rgba(81,124,5,0.75));
					z-index: 3;
					&:before,&:after{}
				}
			}
		}
		&.overwidth{
			li{
				a{
					height: 100%;
					line-height: 1.5;
					display: block;
					padding-bottom: 25px;
					padding-top: 25px;
				}
			}
		}
	}
}
//IE9〜
html:root body {
	#gmenu ul li a{
		&:before,&:after{
			.scale(0);
		}
		&:before{
			border-top:5px solid @gmenuCurrentBorder;
		}
		&:after{
			bottom: -5px;
			border-bottom: 5px solid @gmenuCurrentBg;
		}
		&:hover,
		&.current{
			&:before,&:after{
				.scale(1);
			}
		}
		&.current{
		}
	}
}


//ぱんクズ
.breadcrumbs {
	margin-bottom: 32px;
	padding-top: 20px;
	ol {
		&,
		li,
		li a,
		li a:hover{background-color: transparent;}
		li {
			&>a,
			&>span,
			&> a span{
					color: @breadcrumbsLinkTxt;
			}
			&>a,
			&>span{
				&:after{
					content: '>';
					left: 0.5em;
					right: auto;
					color: @breadcrumbsNormalTxt;
					position: absolute;
				}
			}
			a{
				padding-right: 0.5em;
				padding-left: 1em;
				&:before,
				&:after{
					border: 0;
				}
				&:after{top: 60%;}
				span{padding-left: 1.0em;}
				&:hover,
				&:focus{
					&,span{text-decoration: underline;}
					&:before,
					&:after{
						text-decoration: none;
					}
				}
			}
			&:first-child{
				a{
					padding-left: 0;
					&:before{
						.iconFont('\e610',2.2);
						position: relative;
						width: 22px;
						margin-top: 0;
						font-weight: 400;
						color: @breadcrumbsHome;
						top: 0;
						left: 0;
						line-height: 1.3em;
						display: inline-block;
						vertical-align: top;
						border-width: 0;
					}
					&:focus{
						background: transparent;
						text-decoration: none;
					}
					&:hover{
						&:before{
							.hoverlink;
							text-decoration: none;
						}
					}
					&:after{
						content: '';
					}
					span{.hide-text;}
				}
			}
			&>span{
				position: relative;
				padding-right: 0;
				padding-left: 2em;
				line-height: 2.5em;
				color: @breadcrumbsNormalTxt;
			}
		}
	}
}

//コンテンツタイトル
#main .moduletitle {
	border-top-width: 0;
	margin-bottom: 20px;
	h3{
		border-width: 0 0 2px;
		border-style: solid;
		border-color: transparent transparent @moduletitleBorder;
		letter-spacing: 0.15em;
		background-color:@moduletitleBg;
		color: @moduletitleBorder;
		.font-size(2.6);
		padding-bottom: 14px;
		padding-left: 0;
		height: 43px;height: 4.3rem;
		&:hover{opacity: 100;}
	}
}

//メインイメージ
.mainImg{
	background-color: @mainImgBgColor;
	margin-bottom: 0;
	padding: 20px 0;
	.mainImgInner{
		.centerBlock(@siteW,0,0);
	}
}

//フォーム・ブログ バナー
#main,
#side {
	&> .block {
		.element_blog_banner,
		.element_form_banner {
			.clearfix;
			background: url(../images/dottosLineBan.png) repeat left top @gmenuBgColor;
			a{
				background-color: @banColor;
				color: @banTxtColor;
				font-weight: bold;
				.font-size(1.8);
				position: relative;
				margin: 5px;
				&:before {
					content: '';
				}
				&:hover{
					margin: 0;
					padding: 20px 15px;
					.opacity(100);
				}
			}
		}
	}
}
.entry_formbanner {
	.element_blog_banner {
		background: url(../images/dottosLineBan.png) repeat left top @gmenuBgColor;
		border-collapse: separate;
		border-spacing: .4em;
		&:hover{
			border-spacing: 0;
			a{
				padding: 1em .6em;
			}
		}
		a:hover{
			.opacity(100);
		}
	}
}

//コンセプトブロック
#main,
#side {
	&> .block {
		.conceptelement {
			border: 10px solid @conceptElementBorder;
			background-color: @conceptElementBg;
			.inside{padding: 20px 10px 5px;}
			.title {
				border-top-width: 0;
				margin-bottom: 10px;
				h3,
				h4{
					color: @conceptElementTitle;
					font-weight: bold;
					display: block;
					letter-spacing: 0.18em;
					height: 1.8rem;
				}
				h3{}
				h4{text-align: center;}
			}
			.concept{
				&,p{color: @conceptElementTxt;}
			}
		}
	}
}

//サイト情報ブロック
#main > .block .main_contactelement {
	border: 17px solid @contactElementBorder;
	padding: 22px 40px;
	.inside_contactElement {position: relative;}
	.title h3{
		color: @sitePointColor;
		.font-size(2.6);
		font-weight: bold;
	}
	h4{
		color: @contactNoTxtColor;
		letter-spacing: 0.1em;
		font-weight: bold;
	}
	.telnumber {
		width: 100%;
		th,td{
			position:relative;
			z-index: 1;
			font-family: 'Roboto';
		}
		th {
			padding: 0 0 0;
			text-align: left;
			& ,a{color: @contactNoTitleColor;}
			.font-size(2.6);
			&:first-of-type{
				padding-right: 2em;
			}
		}
		td {
			width: 67px;
			padding-right: 12px;
			span{
				width: auto;
				background-color: transparent;
				line-height: 30px;
				color: @contactNoTxtColor;
				.font-size(2.2);
				margin: 0;
			}
		}
	}
	.contact_detail {
		margin-bottom: 20px;
		tr{border-top-color: @contactePageTable;}
		th,td{letter-spacing: 0.1em;}
		th{
			color:@contactNoTxtColor;
			.font-smoothing-auto;
		}
		td{color: @contactTxtColor;}
	}
	.btn_t a{
		&:after{content: '';}
		&,&:hover{
			color: @banTxtColor;
			background-color: @banColor;
		}
		&:hover{.hoverlink;}
	}
}
#side > .block .contactelement{
	border: 5px solid @contactElementBorder;
	.title h4 {
		text-align: center;
		font-weight: bold;
		color: @sitePointColor;
		margin-bottom: 10px;
	}
	.contact_telno {
		table{width: 100%;}
		th,td{
			padding-bottom: 3px;
			.font-smoothing-auto;
		}
		th {
			padding: 0 0 0;
			font-weight: bold;
			text-align: left;
			font-family: 'Roboto';
			&,& a{color: @contactNoTitleColor;}
		}
		td {
			padding-right: 5px;
			.sidetel,
			.sidefax{
				font-family: 'Roboto';
				margin: 0;
				color: @contactNoTxtColor;
				background-color: transparent;
				width: auto;
			}
		}
	}
	.contents_contact{
		.contact_p{
			span{
				display: block;
				color: @contactTxtColor;
				border-bottom-color: @contactePageTable;
				&:first-of-type{
					color: @contactNoTxtColor;
					.font-smoothing-auto;
				}
			}
		}
		& > div[align="center"] {
			position: relative;
			a{
				&:after{content: '';}
				&,&:hover{
					color: @banTxtColor;
					background-color: @banColor;
				}
				&:hover{.hoverlink;}
			}
		}
	}
}


//NEWマーク
#main > .block,
#side > .block,
#main > .element_list{
	h5 {
		& > .new,
		& + .new{
			&:after{
				background-color: @newIconBg;
				color: @newIconTxt;
				.border-radius(2px);
				.font-size(2.1);
				width: 1.7em;
				height:0.76em;
				line-height: 0.75;
				vertical-align: -3px;
			}
		}
	}
}
//ブロック タイトル
#main > .block,
#side > .block{
	.element {
		&>.wrapLink{
			&:hover{
				background-color: transparent;
			}
		}
	}
}

#main > .block {
	.element {
		.extitle {
			border-width: 0 0 2px;
			border-style: solid;
			border-color: @blocktitleTxt;
			margin-bottom: 20px;
			position: relative;
			padding-right: 15px;
			&:hover{
				background-color: transparent;
			}
			&> .title {
				border: 0 none;
				letter-spacing: 0.2em;
				padding-right: 0;
				padding-left: 0;
				h3{
					color: @blocktitleTxt;
					height: 44px;height: 4.4rem;
					.font-smoothing-auto;
					padding: 0;
					.font-size(2);
				}
			}
			&> .listicon {
				position: relative;
				width: 85px;
				padding-top: 4px;
				a{
					position: relative;
					display: block;
					background-color: @listiconBg;
					height: 26px;
					&:before{
						content: '一覧へ';
						color: @listiconTxt;
						letter-spacing: 0.2em;
						padding-left: 1em;
						.font-smoothing-auto;
						line-height: 26px;
					}
					&:after {
						content: "";
						border-bottom: 13px solid transparent;
						border-left: 13px solid @listiconBg;
						border-top: 13px solid transparent;
						display: block;
						width: 0;
						height: 0;
						margin-top: -13px;
						position: absolute;
						top: 50%;
						right: -13px;
					}
					&:hover{
						.hoverlink;
						&,&:before{text-decoration: none;}
					}
				}
			}
		}
	}
	.layout_b_pattern1,
	.layout_b_pattern2,
	.layout_b_pattern3,
	.layout_b_pattern4,
	.layout_b_pattern5,
	.layout_b_pattern7{
		//&> .wrapLink,
		.extitle{
			margin-bottom: 10px;
		}
	}
	.layout_b_pattern11,
	.layout_b_pattern12,
	.layout_b_pattern13,
	.layout_b_pattern14,
	.layout_b_pattern15,
	.layout_b_pattern17{
		.extitle{margin-bottom: 25px;}
	}

}
#side > .block {
	.element > .title {
		padding-bottom: 5px;
		border-top-width: 0;
		&,&:hover{background: url(../images/dottosLine.png) repeat-x bottom left @blocktitleBorderColor;}
		h4{
			border-bottom: 1px solid @sideBlocktitleBorderColor;
			.font-size(1.8);
			line-height: 18px;line-height: 1.8rem;
			letter-spacing: 0.10em;
			padding: 14px 12px 14px;
			height: auto;
			&,& a{color: @sideBlocktitleTxt;}
		}
	}
	.layout_b_pattern6 > .title,
	.layout_b_pattern6 > .title:hover{
		padding-bottom: 0;
		background-image: none;
		h4{
			border-bottom:0 none
		}
	}
	
}

//ブロック・一覧 カテゴリ
#main > .block h4.catetitle,
#side > .block h4.catetitle,
#main > .element_list h4.catetitle,
#main > .block .block_title > h4,
#side > .block .block_title > h4,
#main > .element_list .block_title > h4,
#main > .block .catart .cat > li,
#side > .block .catart .cat > li,
#main > .element_list .catart .cat > li,
#main > .block .list_title1 h4,
#side > .block .list_title1 h4,
#main > .element_list .list_title1 h4 {
	a{
		.banLinkColorOf;
		line-height: 29px;
		border-color: @white @white @catetitleBorderColor;
		.font-smoothing-auto;
		&:hover{
			color:@banTxtColor;
			background-color: @banColor;
		}
	}
}
#main{
	& > .block {
		.layout_b_pattern4,
		.layout_b_pattern7,
		//.layout_b_pattern5,
		.layout_b_pattern14,
		.layout_b_pattern15{
			h4.catetitle,
			.block_title > h4 {
				margin-bottom: 5px;
			}
		}
		.layout_b_pattern6 .list_cat > .title a{
			.banLinkColorOf;
			border-color: @cateListBorder;
			border-width: 0 0 0 3px;
			border-style: solid;
			background-color: @cateListBg;
			.font-size(1.4);
			font-weight: bold;
			line-height: 1.3;
			padding: 11px 6px 11px;
			text-align: left;
			&:after {
				content: '';
			}
			&:hover{
				.banLinkColor;
				border: 0 none;
			}
		}
	}
	& > .element_list .list_title1{
		&+ .list_text,
		& + .list1 {
			//margin-top: 0;
			margin-bottom: 50px;
		}
	}
	&>.block_head{.clearfix;}
}
#side > .block {
	.contents_cat{margin-top: 5px;}
	.layout_b_pattern6 {
		.contents_cat{margin-top: 1px;}
		.catart .cat > li {
			margin-bottom: 1px;
			a{
				border-color: @cateListBorder;
				border-width: 0 0 0 3px;
				border-style: solid;
				background-color: @cateListBg;
				font-weight: bold;
				padding: 10px 6px;
				font-weight: bold;
				line-height: inherit;
				&:hover{
					background-color: @cateListBorder;
				}
			}
		}
	}
	//.layout_b_pattern1 h5,
	.layout_b_pattern11 .contents_imgflo{
		padding-top: 10px;
	}
	.catart .cat > li a{padding: 5px;}

}
#main > .block ,
#side > .block,
#main > .element_list {
	h5,
	.artloop h5 {
		.catetitle {
			&,& a{color: @catetitleTxt;}
		}
	}
}

//ブロック・一覧 サムネイル
#main > .block .list_imgflo .artloop,
#main > .element_list .list_imgflo {
	.list4{border-top-width: 0;}
	.thum_img {
		img{}
	}
}
#main > .block {
	.layout_b_pattern4,
	.layout_b_pattern7,
	.layout_b_pattern5,
	.layout_b_pattern14,
	.layout_b_pattern15 {
		.artloop {padding: 20px;}
	}
}

//ブロック・一覧 
#main > .block ,
#side > .block ,
#main > .element_list {
	p{color: @listEntryTxt;}
	.artloop{
		border: 1px solid transparent;
		&:hover {
			background-color:@listBlockOver;
			border-color: darken(@listBlockOver,10%);
			.thum_img {
				&:before, &:after{.opacity(100);}
			}
		}
		.date{color:@dateTxt;}
		h5 {
			margin-bottom: 10px;
			&> a {
				font-weight: bold;
				color: @listTitleTxt;
			}
		}
	}
	.contents > .wrapLink{
		.artloop{
			border-width: 1px;
			&:hover {
			//	background-color:@listBlockOver;
				border-color: darken(@listBlockOver,10%);
			}
		}
		&.evenBlock{
			.artloop{
				background-color:@listBlockOver;
				&:hover {
					background-color:@white;
				}
			}
		}
	}
}
#main{
	& > .block,
	& > .element_list {
		.date{margin: 0 0 5px;}
	}
}
#side > .block {
	.date{
		margin: 0 0 5px;
		color:@dateTxt;
	}
	.contents{
		h5 {}
	}
	.element {
		.art,
		.contents_text,
		.contents_imgflo,
		.art > .wrapLink,
		.contents_text > .wrapLink,
		.contents_imgflo > .wrapLink{
			&> .contents{
				&:hover {background-color:@listBlockOver}
				p.catch{color: @detailCatch;}
				h5 {
					margin-bottom: 10px;
					&> a {
						color: @listTitleTxt;
						font-weight: bold;
					}
				}
			}
		}
	}
}
//ブロック・一覧 パターン別
#main > .block {
	.layout_b_pattern4,
	.layout_b_pattern7,
	.layout_b_pattern5,
	.layout_b_pattern14,
	.layout_b_pattern15{
		.artloop {
			//padding: 22px 0;
			//border: 0 none;
			border: 1px solid transparent;
			margin-bottom: 0 !important;
			&:hover {border-color: darken(@listBlockOver,10%);}
		}
	}
	.layout_b_pattern4,
	.layout_b_pattern14{
		.contents > .wrapLink .artloop {border-width: 1px 0;}
	}
	.layout_b_pattern1,
	.layout_b_pattern2,
	.layout_b_pattern11,
	.layout_b_pattern12{
		.artloop {margin: 0;}
	}

	.layout_b_pattern2,
	.layout_b_pattern12{
		.wrapLink{
			&.artBlock6,
			&.artBlock8,
			&.artBlock14,
			&.artBlock16,
			&.artBlock22,
			&.artBlock24{
				.list2.artloop{
					background-color:transparent;
					&:hover {
						background-color:@listBlockOver;
						border-color: darken(@listBlockOver,10%);
					}
				}
			}
			&.artBlock5,
			&.artBlock7,
			&.artBlock13,
			&.artBlock15,
			&.artBlock21{
				.list2.artloop{
					background-color:@listBlockOver;
					&:hover {
						background-color:@white;
						border-color: darken(@listBlockOver,10%);
					}
				}
			}
		}
	}
	.layout_b_pattern8,
	.layout_b_patter17 ,
	.layout_b_pattern17 ,
	.layout_b_pattern3,
	.layout_b_pattern13{
		.artloop {
			margin-bottom: 0 !important;
			border: 1px solid transparent;
		}
		.wrapLink{
			&.artBlock2,
			&.artBlock3,
			&.artBlock6,
			&.artBlock7,
			&.artBlock10,
			&.artBlock14,
			&.artBlock15,
			&.artBlock18,
			&.artBlock19{
				.list3.artloop{
					background-color:@listBlockOver;
					&:hover {
						background-color:@white;
						border-color: darken(@listBlockOver,10%);
					}
				}
			}
			&.artBlock1,
			&.artBlock4,
			&.artBlock5,
			&.artBlock8,
			&.artBlock9,
			&.artBlock12,
			&.artBlock12,
			&.artBlock13,
			&.artBlock16,
			&.artBlock17,
			&.artBlock20,
			&.artBlock21{
				.list3.artloop{
					background-color:transparent;
					&:hover {
						background-color:@listBlockOver;
						border-color: darken(@listBlockOver,10%);
					}
				}
			}

		}
	}
	.layout_b_pattern3,
	.layout_b_pattern13{padding: 20px;}
	.layout_b_patter17 ,
	.layout_b_pattern17 {}

	.layout_b_pattern3,
	.layout_b_pattern8,
	.layout_b_pattern13,
	.layout_b_pattern17 {
		.contents {
			margin-bottom: 40px;
		}
	}
}
#side > .block {
	.layout_b_pattern5,
	.layout_b_pattern15 {
		.art,
		.contents.contents_text {
			.contents h5.titleOnly {
				margin-bottom: 0;
				padding: 0;
			}
		}
		.art .contents,
		.contents.contents_text .contents {
			border: 0 none;
			border-bottom: 1px solid @lignt-green03;
			padding: 15px 5px;
		}
//		.wrapLink:nth-child(even){
//			.contents {
//				background-color:@listBlockOver;
//				&:hover {
//					background-color:@white;
//				}
//			}
//		}

	}
	.layout_b_pattern15 {
		.contents.contents_text {
			.contents{padding: 8px;}
			.wrapLink {
				.contents{
					padding: 8px;
					border-bottom: 0 none;
					border-top: 1px solid @listBorderColor;
				}
				&:first-child{
					.contents{border-top: 0 none;}
				}
			}
		}
	}
	.layout_b_pattern12,
	.layout_b_pattern13,
	.layout_b_pattern14{
		.title_side_block + .contents .contents {
			border-top: 1px solid @listBorderColor;
			padding: 13px 5px 8px;
		}
		.title_side_block + .contents .wrapLink .contents {
			border-top: 1px solid @listBorderColor;
		}
	}

	.layout_b_pattern2,
	.layout_b_pattern3,
	.layout_b_pattern4{
		.art{
			.contents,
			.wrapLink .contents{
				padding: 10px 5px;
				border-top: 1px solid @listBorderColor;
			}
		}
	}
}
#main > .element_list {
	//margin-top: -25px;
	.list_sort_links a{
		//margin-top: 40px;
		.banLinkColorOf;
		&:hover{.banLinkColor;}
	}
	.list_imgflo{
		margin-bottom: 40px;
		.list1,
		.list2{margin-bottom: 0;}
		&.img-list2 {
			.wrapLink{
				&.artBlock6,
				&.artBlock8,
				&.artBlock14,
				&.artBlock16,
				&.artBlock22,
				&.artBlock24{
					.list2.artloop{
						background-color:transparent;
						&:hover {
							background-color:@listBlockOver;
							border-color: darken(@listBlockOver,10%);
						}
						
					}
				}
				&.artBlock5,
				&.artBlock7,
				&.artBlock13,
				&.artBlock15,
				&.artBlock21{
					.list2.artloop{
						background-color:@listBlockOver;
						&:hover {
							background-color:@white;
							border-color: darken(@listBlockOver,10%);
						}
					}
				}
			}
		}
		.list4{
			&,
			&:first-child{}
		}
		&> .wrapLink .list4{
			border-width: 1px 0;
			border-color: transparent;
		}
		.list3{
			border: 1px solid transparent;
			margin-bottom: 0 !important;
		}
		.wrapLink{}
	}
	.list_text {
		.list1,
		.list2,
		.list3,
		.list4,
		&.list4 .artloop {
			border-width: 1px 0;
			border-color: transparent;
			border-left-color: transparent;
			border-right-color: transparent;
		}
		&> .wrapLink .list1{
			border-width: 1px 0;
		}
		.wrapLink .list2{
			//background-color:@listBlockOver;
			&:hover {
				border-color: darken(@listBlockOver,10%);
			}
		}
		.evenBlock{
			.list1,
			.list2,
			.list3,
			.list4,
			&.list4 .artloop {
				background-color:@listBlockOver;
				&:hover {
					background-color:@white;
					border-color: darken(@listBlockOver,10%);
				}
			}
			&,
			&:first-child{}
		}
	}
	.list_imgflo > .wrapLink{
		//border: 1px solid transparent;
		.list3.artloop{
			//background-color:@listBlockOver;
			&:hover {
				background-color:@white;
				border-color: darken(@listBlockOver,10%);
			}
		}
		&.evenBlock{
			.list3.artloop{
				background-color:transparent;
				&:hover {
					background-color:@listBlockOver;
					border-color: darken(@listBlockOver,10%);
				}
			}
		}
		&.artBlock2,
		&.artBlock3,
		&.artBlock6,
		&.artBlock7,
		&.artBlock10,
		&.artBlock14,
		&.artBlock15,
		&.artBlock18,
		&.artBlock19{
			.list3.artloop{
				background-color:@listBlockOver;
				&:hover {
					background-color:@white;
					border-color: darken(@listBlockOver,10%);
				}
			}

		}
		&.artBlock1,
		&.artBlock4,
		&.artBlock5,
		&.artBlock8,
		&.artBlock9,
		&.artBlock12,
		&.artBlock13,
		&.artBlock16,
		&.artBlock17,
		&.artBlock20,
		&.artBlock21{
			.list3.artloop{
				background-color:transparent;
				&:hover {
					background-color:@listBlockOver;
					border-color: darken(@listBlockOver,10%);
				}
			}
		}
	}
}
#main{
	> .block ,
	> .element_list {
		.list_text {
			margin-bottom: 40px;
			.list1,
			.list2,
			.list3,
			.list4,
			&.list4 .artloop {
				&:hover {
					border-left-color: transparent;
					border-right-color: transparent;
				}
			}
		}
	}
}

//一覧ページング
#main .paginationbox {
	a,span{}
	.totalpages{
		color: @pagingTotalpages;
	}
	.pages {
		li {
			a{.pagingLinkColorOf;}
			span{.pagingLinkColor;}
			a:hover{
				.banLinkColor;
				.opacity(100);
			}
		}
	}
	.next,.prev{
		.hide-text;
		.pageNavColorOf;
		width: 145px;
		&:before,&:after{
			color: @pagingIcon;
			//display: block;
			//width: 100%;
			vertical-align: middle;
			.font-size(2.1);
		}
		&.disable{
			border-color: @pagingColorDisable;
			&:before,&:after{
				color: @pagingColorDisableTxt;
			}
			
		}
		&:hover{
			.pageNavColor;
			.opacity(100);
			&:before,&:after{
				color: @pagingTxt;
			}
			&.disable{
				border-color: @pagingColorDisable;
				background-color: @pagingColorDisable;
				color: @pagingColorDisableTxt;
				&:before,&:after{
					color: @pagingColorDisableTxt;
				}
			}
		}
	}
	.prev:before{
		content: '\e617';
		margin-right: 0.5em;
	}
	.next:after{
		content: '\e618';
		margin-left: 0.5em;
	}
}

//詳細
#main > .element_detail {
	h4.catetitle{
		.banLinkColorOf;
		border-width:0 0 1px;
		line-height: 29px;
		border-bottom: 1px solid @catetitleBorderColor;
		text-indent: 0;
		.font-smoothing-auto;
		.font-size(1.4);
		padding: 5px 9px;
	}
	.hs_entrytitle{
		border-bottom: 0 none;
		color: @detailTitle;
		font-weight: bold;
		.font-size(2.4);
		padding-bottom: 0;
	}
	.catchcp{
		color: @detailCatch;
		font-weight: bold;
	}
	.entry{
		color: @detailEntryTxt;
		p,
		strong,
		b,
		ul li,
		ol li,
		table tbody tr td{color: inherit;}
	}

	.paginationbox {
		.backbtn a {
			.pagingLinkColorOf;
			&:hover{.pagingLinkColor;}
		}
		.next,.prev{
			.pageNavColorOf;
			&.disable{
				border-color: @pagingColorDisable;
				background-color: @pagingColorDisable;
				color: @pagingColorDisableTxt;
				&:before,&:after{
					color: @pagingColorDisableTxt;
				}
			}
			&:hover{
				.pageNavColor;
				&.disable{
					border-color: @pagingColorDisable;
					background-color: @pagingColorDisable;
					color: @pagingColorDisableTxt;
					&:before,&:after{
						color: @pagingColorDisableTxt;
					}
				}
			}
		}
	}
}

//カート
#main > .block ,
#side > .block ,
#main > .element_list{
	p.cart_list_price {
		.price {color: @cartPriceTxt;}
		.price2{color: @cartPrice2Txt;}
	}
}
#side > .block .element_side_cart .cart_detail {
	background-color: @cartDetailBlockBg;
	.basketItem td {}
}
#main > .element_detail .entry > .entry_cart .detail_item {
	.hs_cartDetailTable td{
		&,span{color: @siteTxt;}
	}
	.salePrice td {
		.priceTxt{color: @cartPriceTxt;}
	}
	.detail_button .cartinquiry{
		background-color: @cartInquiryBg;
		border-color: @cartInquiryBorder;
		color: @cartInquiryTxt;
		&:hover{.hoverlink;}
	}
	.detail_guidelink a{
		border-color: @cartGuideLinkborder;
		color: @cartGuideLinkTxt;
		&:hover{background-color: @cartGuideLinkTxtOn;}
	}
}
.neederror{color:@neederror;}
.shopping_progress {
	.stepprogre {
		border-color: @cartStepprogreBorder;
		li > span{
			background-color:@cartStepprogre;
			&:before{border-left-color: @cartStepprogre;}
		}
	}
	input{
		&[type="button"],
		&[type="submit"] {
			.banLinkColorOf;
			&:hover{
				.banLinkColor;
			}
		}
	}
	table.navigateBtn {
		input{
			
			&[type="button"]{
				.banLinkColorOf;
				&:hover{.banLinkColor;}
			}
			&[type="submit"]{
				&,&.next{
					background-color: @siteColor;
					border-color: @siteColor;
					color: @white;
					&:hover{.banLinkColor;}
				}
			}
			&[type="submit"]:first-child{
				.banLinkColorOf;
				&:hover{.banLinkColor;}
			}
		}
		th{
			&:first-of-type{
				input[type="submit"]{
					background-color: @siteColor;
					border-color: @siteColor;
					color: @white;
				}
			}
			&:first-clhild{
				input[type="submit"].orderbuttom{
					.banLinkColorOf;
					&:hover{.banLinkColor;}
				}
			}
		}
	}
	.formbox {
		.details_input.confirm {
			th{}
			td{
				background-color: @cartInputTableThBg;
			}
		}
		.details_totalprise .priceTotal{color: @cartPriceTxt;}
		.details tr{border-bottom-color: @cartGuideLinkborder}
	}
}
#main > .element_shopguide .entry .advtable {
	th,td{
		&, a{color: @sitePointColor;}
	}

	td{
		&, a{color:inherit;}
	}
}
#main > .element_cart_inquiry {
	.formbox {
		.confirm {
			margin-bottom: 50px;
			width: 100%;
			th {
				padding: 10px 25px 10px 10px;
				vertical-align: middle;
				white-space: nowrap;
				width: 1%;
				text-align: right;
				.font-size(1.4);
				font-weight: normal;
				color: @cartInquiryFormboxTxt;
			}
			td {
				border: 3px solid @cartInquiryFormboxBorder;
				.font-size (1.6);
				background-color: @cartInquiryFormboxBg;
				border-color: @cartInquiryFormboxBorder2;
				color: @cartInquiryFormboxTxt;
			}
		}
	}
	table.navigateBtn {
		td{text-align: center;}
		input{
			&[type="submit"]{
				.font-size (1.6);
				padding: 7px 20px;
				.border-radius(3px);
				.banLinkColorOf;
				&:hover{.banLinkColor;}
			}
			&[type="submit"].orderbutton,
			&[type="submit"].orderbuttom {
				background-color: @siteColor;
				border: 1px solid @siteColor;
				color: @white;
				&:hover{.banLinkColorOf;}
			}
		}
	}
}


//フォーム
.element_mailform .contents > .formflow {
	li{
		background-color: @cartStepprogre;
		color: @sitePointColor;
		&.flow1,
		&.flow2{
			&:after{color: @sitePointColor;}
		}
	}
}

//ブログ
#main #comment_main ul li{
	&,& a{color: @sitePointColor;}
}
.post_detail{
	& > .date,
	& > .category {color: @dateTxt;}
	& > .commentCount{
		.countTitle{color: @sitePointColor;}
		.count{
			background-color:@siteColor;
			&:after{border-top-color:@siteColor;}
		}
	}
}
#commentForm{
	.commentFormTitle{
		border-color: @cartStepprogreBorder;
		&:hover,
		&.open {background-color: @contactElementBorder;}
	}
	#formComment{border-color: @cartStepprogreBorder;}
}
.comment_list {
	&>strong{color: @sitePointColor;}
	&> .comments {
		.commentHead{
			background-color:@catetitleBg;
			p{
				&,& a{color: @sitePointColor;}
			}
		}
		.commentBody{border-color:@catetitleBg;}
	}
}

//会社概要
#main > .element.profile .module_profile_table {
//	tr{border-color: @gmenuBorderColor;}
	th,td{
		&,& a{color: @sitePointColor;}
	}
}

//サイトマップ
#main > .element.sitemap {
	.cat > li h4.catetitle a{
		&:hover{
			border-color:@catetitleBgHover;
			background-color: @catetitleBgHover;
			color: @sitePointColor;
		}
	}
	.art .contents {
		h5{
			&,& a{color: @sitePointColor;}
			&:before{
				content:'\e612';
			}
		}
		p a{color: @sitePointColor;}
	}
}

//フッター
footer{
	padding-top: 5px;
	background-color: @siteColor;
	position: relative;
	.pagetopBtn{
		margin: -35px auto 0;
		.size(85px,30px);
	}
	a.pagetop{
		.hide-text;
		position: relative;
		display: block;
		padding: 0;
		height: 30px;
		&:after ,&:before{position: absolute;}
		&:before{
			content: '';
			display: block;
			border-color: transparent transparent @pagetopBtnBg transparent;
			border-style: solid;
			border-width: 0 40px 30px;
		}
		&:after {
			color:@pagetopIconColor;
			.iconFont('\e613',2.1);
			left: 36%;
			top: 15px;
		}
	}
	#footerNav{
		padding-top: 62px;
		padding-bottom: 62px;
		background-color: @footerNavBg;
		.innerFooterNav{
			.centerBlock(@siteW,0,0);
			.clearfix;
		}
		.footerContact {
			float:left;
			width: 225px;
			text-align: left;
			.siteName {
				color: @footerSiteName;
				font-weight: bold;
				.font-size(2.5);
				line-height: 1.3;
				letter-spacing: 0.12em;
				margin-bottom: 20px;
			}
			.contactList {
				list-style: none;
				margin-bottom: 0;
				li {
					.font-size(1.2);
					letter-spacing: 0.1em;
					font-weight: normal;
					margin-top: 0;
					margin-bottom: 0.5em;
					&,& a{color: @footerContactList;}
					&,address{.font-smoothing-auto;}
					address {font-style: normal;}
					span {padding-right: 1em;}
				}
			}
		}
		.footerNavLink{
			float:right;
			width: 710px;
			.contentsNav,
			.subNav{
				text-align: left;
				display: block;
				li {
					display: inline-block;
					a{
						color: @footerNavTxtColor;
					}
				}
			}
		}
		.contentsNav {
			border-left: 0 none;
			width: auto;
			margin-bottom: 20px;
			li {
				border-right: 0 none;
				width: auto;
				a{
					position: relative;
					&:before {
						color: @footerNavIconColor;
						line-height: 8px;
						.iconFont('\e612',1.0);
						padding-right: 0.3em;
						vertical-align: 1px;
					}
					&:hover{
						color: lighten(@footerNavTxtColor, 10%);
					}

				}
			}
		}
		.subNav {
			margin-top: 0;
			padding-bottom: 0;
			li {
				border-left: 1px dotted @footerNavTxtColor;
				.font-size(1.0);
				&:first-child{
					border-left: 0 none;
					margin-left: 0;
					a{padding-left: 0;}
				}
				a{
					line-height: 1em;
					&:hover{color: lighten(@footerNavTxtColor, 10%);}
					&:before{
						content: '';
					}
				}
			}
		}
	}
	#copyBlock{
		background-color:@copyBlockBgColor;
		padding: 30px 0;
		.box-shadow(0 3px 5px rgba(0,0,0,0.15) inset);
	}
	.copyright,
	.promotion{
		margin: 0;
		padding: 2px 0;
		a{
			&,&:visited{color: @copyBlockTxtColor;}
		}
	}
	.pv_switch {
		a {
			background: @pvSwitchBg;
			color:@pvSwitchTxt;
		}
	}

}

//トップページ
.is--home{
	#gmenu{margin-bottom: 44px;}
}