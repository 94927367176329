//*****************************************
//*	共通部(メインカラム・サイドカラム)
//*****************************************/
#main > .block,
#side > .block {
	//*****************************************
	//*	共通黄色ブロック
	//*****************************************/
	//カートを見る
	.element_side_cart {
		>.title {
			width: 100%;
			display: table;
			border-top: 1px solid darken(@light-grey, 21.5%);
			h4 {
				display: table-cell;
				.font-size(1.8);
				height: 57px;
				height: 5.7rem;
				vertical-align: middle;
			}
			&:hover {
				.transition(background @transition-fast ease-in-out);
				background: @light;
			}
		}
		.cart_detail {
			th {
				background: @light-grey;
				text-align: center;
				.font-size(1);
				padding: .3em;
				vertical-align: middle;
				letter-spacing: .2em;
			}
			.basketItem {
				border-top: 1px solid @light-grey;
				&:first-child {
					border-top: none;
				}
				td {
					.font-size(1.2);
					&:last-child {
						width: 1%;
						.font-size(1.4);
						font-weight: 400;
						font-family: 'Roboto';
					}
				}
				a {
					text-decoration: underline;
					&:hover {
						text-decoration: none;
					}
					&.thum_img {
						display: none;
						+br {
							display: none;
						}
					}
				}
			}
		}
		.cart_more {
			display: none;
		}
		.cart_total {
			border-top: 1px solid @light-grey;
			.shipping, .cod, .tax {
				th {
					padding: 0 10px;
					text-align: left;
				}
				td {
					padding: .1em 10px;
					font-weight: 400;
					font-family: 'Roboto';
				}
				border-top:1px solid @light-grey;
			}
			.total {
				th, td {
					background: @light-grey;
					padding: .1em 10px;
				}
				th {
					text-align: left;
				}
				td strong {
					//合計金額
					.font-size(2);
					font-weight: 600;
					font-family: 'Roboto';
				}
			}
			td[colspan="2"] {
				.font-size(1);
				padding: 0;
				sup {
					top: 0;
				}
			}
			.cart_detailTxt{font-size:11px;}
			sup{
				font-size:13px !important;
				/*Firefox*/
				@-moz-document url-prefix() {
					font-size:11px !important;
				}
				/*webkit*/
				@media screen and (-webkit-min-device-pixel-ratio:0){
					font-size:11px !important;
				}

			}
			
		}
		.kagoBody {
			margin: 0;
			>a {
				text-align: center;
				.font-size(1.4);
				font-weight: 600;
				display: block;
				line-height: 1.5em;
				padding: 10px 15px;
				background: @red;
				color: @white;
				text-decoration: none;
				.transition(opacity @transition-fast ease-in-out);
				&:before {
					.font-size(1.9);
					font-weight: 400;
					vertical-align: middle;
					font-family: 'hybstemplate';
					content: "\e600";
					margin-right: .3em;
				}
				&:hover {
					background: lighten(@red, 10%);
				}
			}
			
		}
	}
	//ブログバナー
	.element_blog_banner,
	.element_form_banner {
		a {
			text-decoration: none;
			.font-size(1.4);
			line-height: 1.3em;
			letter-spacing: .2em;
			display: block;
			background: @inverse;
			color: @white;
			text-align: center;
			padding:15px 10px;
			.transition(opacity @transition-fast ease-in-out);
			&:before {
				display: inline-block;
				.font-size(2.3);
				line-height: .6;
				font-family: 'hybstemplate';
				content: "\e603";
				margin-right: .3em;
				vertical-align: top;
			}
			&:hover {
				opacity: .8;
				-ms-filter: alpha(opacity=80);
				filter: alpha(opacity=80);
			}
		}
	}
	.element_form_banner a:before {
		line-height: 1;
		.font-size(1.7);
		content: "\e601";
	}
	//ごあいさつ
	.conceptelement {
		.title {
			border-top: 1px solid darken(@light-grey, 21.5%);
			h3, h4 {
				display: table-cell;
				.font-size(1.8);
				height: 57px;
				height: 5.7rem;
				vertical-align: middle;
				margin-bottom: 0;
			}
		}
		.contents {
			>p {
				margin-top: 0;
				text-align: justify;
				line-height: 1.7;
			}
		}
	}
}

#side > .block {
	//*****************************************
	//*	サイドカラムブロック共通部
	//*****************************************/
	.element {
		>.title {
			width: 100%;
			display: table;
			border-top: 1px solid darken(@light-grey, 21.5%);
			h4 {
				display: table-cell;
				.font-size(1.8);
				height: 57px;
				height: 5.7rem;
				padding: 10px 0;
				line-height: 1.5;
				vertical-align: middle;
			}
			&:hover {
				.transition(background @transition-fast ease-in-out);
				background: @light;
			}
		}
		.catart .cat {
			list-style: none;
			margin: 0;
			li {
				margin-bottom: 16px;
			}
		}
		.art,
		.contents_text,
		.contents_imgflo {
			margin: 0;
			>.contents,
			> .wrapLink >.contents{
				margin-bottom: 29px;
				.transition(background @transition-fast ease-in-out);
				h5 {
					.font-size(1.4);
					line-height: 1.4;
					margin-bottom: 0;
					font-weight: 600;
					&.titleOnly{
						font-weight: 400;
					}
					>a {
						&:hover {
							text-decoration: underline;
						}
					}
				}
				&:hover {
					background: @light;
				}
				p.catch {
					margin: .5em 0;
				}
				p.cart_list_price {
					.price {
						display: block;
					}
				}
			}
			table {
				tr td {
					padding: 0;
					vertical-align: top;
					&:first-child {
						width: 1%;
						padding-right: 9px;
					}
				}
			}
		}
		.art .contents{
			width: 100%;
			overflow: hidden;
		}
		.thum_img {
			img {
				.square(64px);
			}
		}
	}
	//*****************************************
	//*	サイドカラムブロックパターンごと
	//*****************************************/
	//画像横並びパターン
	.layout_b_pattern1, //分類
	.layout_b_pattern11 //新着
	{
		.art {
			margin: 0 0 16px;
			.clearfix;
		}
		h5 {
			//個別記事
			width: 33.33%;
			margin-top: -2px;
			margin-bottom: 0;
			float: left;
			padding: 2px;
			a{
				img{
					box-shadow: @black 0 0 0;
					background-color: @white;
					.transition(opacity @transition-fast ease-in-out);
				}
				&:hover img{
					opacity: .8;
					-ms-filter: alpha(opacity=80);
					filter: alpha(opacity=80);
				}
			}
		}
	}
	//画像あり1列パターン
	.layout_b_pattern2, //分類
	.layout_b_pattern3, //分類
	.layout_b_pattern4 //分類
	{
		.catart .cat {
			li {
				margin-bottom: 0;
			}
		}
		.art {
			.contents,
			.wrapLink .contents{
				border-top: 1px solid @light-grey;
				padding: 8px 0;
				margin-bottom: 0;
			}
			.wrapLink:first-child .contents {
				border-top: none;
			}
		}
	}
	.layout_b_pattern12, //新着
	.layout_b_pattern13, //新着
	.layout_b_pattern14 //新着
	{
		.title_side_block +.contents {
			.contents {
				padding: 8px 0;
				margin-bottom: 0;
				border-top: 1px solid @light-grey;
				&:hover {
					.transition(background @transition-fast ease-in-out);
					background: @light;
				}
			}
			.wrapLink:first-child .contents{
				border-top: none;
			}
		}
		.date {
			margin: 0 0 .5em;
		}
	}
	//画像なし1列パターン
	.layout_b_pattern5, //分類
	.layout_b_pattern15, //新着
	{
		.catart .cat {
			li {
				margin-bottom: 0;
			}
		}
		.art,
		.contents.contents_text {
			.contents {
				padding: 8px 0;
				margin-bottom: 0;
				border-top: 1px solid @light-grey;
				p {
					margin-top: 0;
				}
				.date {
					margin-top: 0;
					margin-bottom: .5em;
				}
				h5.titleOnly {
					padding-left: 9px;
					padding-left: .9rem;
					a{
						text-decoration: none;
					}
				}
			}
			.wrapLink:first-child .contents{
				border-top: none;
			}
		}
	}
	//カテゴリのみパターン
	.layout_b_pattern6 //分類
	{
		.title_side_block{display: none;}
		.catart .cat li {
			margin-bottom: -1px;
		}
	}
	//*****************************************
	//*	サイドカラムブロック:黄色ブロック
	//*****************************************/
	//コンタクト
	.contactelement {
		padding: 20px 17px;
		border: 1px solid darken(@light-grey, 10%);
		.title {
			h4 {
				margin-bottom: 20px;
				.font-size(1.8);
			}
		}
		.contact_telno {
			th, td {
				padding: 0 0 2px;
				img {
					display: none;
				}
				.sidetel,
				.sidefax {
					.font-size(1.2);
					display: inline-block;
					line-height: 1.5em;
					width: 3em;
					margin-right: .5em;
					background: @inverse;
					color: @white;
				}
			}
			th {
				text-align: left;
				.font-size(1.4);
				font-weight: normal;
				letter-spacing: .1em;
			}
		}
		.contact_p {
			margin-bottom: 3px;
			span{
				display: block;
				border-bottom: 1px solid @light-grey;
				padding:.1em 0;
			}
		}
		address {
			font-style: normal;
			margin-bottom: 1.4em;
		}
		.contents_contact >div[align="center"] {
			a {
				text-decoration: none;
				display: block;
				background: @light-grey;
				line-height: 2em;
				.transition-property(~"background,color");
				.transition(@transition-fast ease-in-out);
				img {
					display: none;
				}
				&:before {
					content: '詳細はこちら';
				}
				&:after {
					.font-size(1);
					margin-left: 1em;
					font-family: 'hybstemplate';
					content: '\e609';
				}
				&:hover {
					background: @inverse;
					color: @white;
				}
			}
		}
	}
}

#main > .block {
	//*****************************************
	//*	メインカラムブロック共通部
	//*****************************************/
	.element {
		.extitle {
			display: table;
			width: 100%;
			border-top: 1px solid darken(@light-grey, 21.5%);
			.transition(background @transition-fast ease-in-out);
			&>.title {
				padding-left: 9px;
				padding-left: .9rem;
				h3 {
					display: table-cell;
					.font-size(1.8);
					height: 57px;
					height: 5.7rem;
					padding:10px 0;
					line-height: 1.5;
					vertical-align: middle;
				}
			}
			&>.listicon {
				width: 1%;
				display: table-cell;
				vertical-align: middle;
				a {
					height: 100%;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
					&:after {
						text-decoration: none;
						display: inline-block;
						width: 26px;
						font-family: 'hybstemplate';
						content: "\e609";
					}
					img {
						//テンプレートデフォルトの<img>を非表示
						display: none;
					}
				}
			}
			&:hover {
				background: @light;
			}
		}
	}
	//*****************************************
	//*	メインカラムブロックパターンごと
	//*****************************************/
	//画像横並びパターン
	.layout_b_pattern1, //分類
	.layout_b_pattern2, //分類
	.layout_b_pattern11, //新着
	.layout_b_pattern12 //新着
	{
		.contents {
			.clearfix;
		}
		.artloop {
			width: 25%;
			padding: 10px;
			margin: 0 0 29px 0;
			float: left;
			&.list1 h4 {
				//イメージのみの場合
				margin-bottom: 0;
			}
			img {
				.square(160px);
			}
			a.thum_img {
				display: inline-block;
			}
			&.list2 a.thum_img {
				margin-bottom: 14px;
			}
			.catch {
				margin-top: 1em;
			}
		}
		.block_title +.contents{
			margin-top: -14px;
		}
	}
	//2列パターン
	.layout_b_pattern3, //分類
	.layout_b_pattern8, //分類
	.layout_b_pattern13, //新着
	.layout_b_pattern17 //新着
	{
		.contents {
			.clearfix;
		}
		.artloop {
			width: 50%;
			float: left;
			padding: 9px;
			margin: 0 0 29px 0;
			margin-bottom: 29px !important; //テンプレートにmargin-bottom:10px;が記述されているため
			img {
				.square(160px);
				&.small {
					.square(100px);
				}
			}
		}
		.block_title +.contents{
			margin-top: -15px;
		}
	}
	//1列パターン
	.layout_b_pattern4, //分類
	.layout_b_pattern7, //分類
	.layout_b_pattern5, //分類
	.layout_b_pattern14, //新着
	.layout_b_pattern15 //新着
	{
		h4.catetitle, .block_title > h4 {
			margin-bottom: 0;
		}
		.artloop {
			margin-bottom: 0 !important; //テンプレートにmargin-bottom:10px;が記述されているため
			border-top: 1px solid @light-grey;
			padding:14px 9px;
			padding:14px .9rem;
			h5 {
				display: inline-block;
			}
			p {
				margin-top: 0;
			}
			h5 +p{
				margin-top: .7em;
			}
			h5 +p:not(.catch){
				margin-bottom: 0;
			}
			.catch {
				margin: .7em 0;
			}
			.thum_img img {
				.square(220px);
				&.small {
					.square(160px);
				}
			}
			.date {
				margin-top: 0;
			}
		}
		>.contents a.wrapLink:first-child .artloop{
			border-top: none;
		}
	}
	//カテゴリのみパターン
	.layout_b_pattern6 {
		.extitle{
			display: none;
		}
		.list_cat {
			margin: 0 -10px;
			.clearfix;
			>.title {
				float: left;
				display: block;
				width: 25%;
				padding: 0 10px;
				h4 {
					margin-bottom: 20px;
					display: table;
					width: 100%;
				}
				a {
					display: table-cell;
					width: 100%;
					overflow: hidden;
					border: none;
					position: relative;
					.font-size(1.4);
					vertical-align: middle;
					text-align: center;
					padding:9px 1.4em;
					line-height: 1.4;
					text-indent: 0;
					background: @inverse;
					color: @white;
					.transition(opacity @transition-fast ease-in-out);
					&:after {
						.font-size(1.2);
						position: absolute;
						right: .6em;
						top:50%;
						line-height: 1px;
						display: inline-block;
						font-family: 'hybstemplate';
						content: "\e609";
					}
					&:hover {
						opacity: .8;
						-ms-filter: alpha(opacity=80);
						filter: alpha(opacity=80);
					}
				}
			}
		}
	}
	//*****************************************
	//*	メインカラムブロック:黄色ブロック
	//*****************************************/
	//コンタクト
	.main_contactelement {
		border: 1px solid darken(@light-grey, 10%);
		padding: 36px 47px;
		.title {
			h3 {
				.font-size(1.8);
			}
		}
		.telnumber {
			margin-bottom: 20px;
			td {
				padding: 0;
				white-space: nowrap;
				span {
					.font-size(1.5);
					text-align: center;
					letter-spacing: .3em;
					text-indent: .3em;
					display: inline-block;
					line-height: 2em;
					width: 4.5em;
					margin-right: 1em;
					background: @inverse;
					color: @white;
				}
			}
			th {
				.font-size(2.6);
				padding-right: 1em;
				font-weight: normal;
				letter-spacing: .1em;
			}
		}
		h4 {
			.font-size(1.4);
			margin-bottom: 0;
			line-height: 2;
			padding-left: .5em;
		}
		.contact_detail {
			margin-bottom: 28px;
			width: 100%;
			tr {
				border-top: 1px solid @light-grey;
			}
			th,
			td {
				.font-size(1.2);
				line-height: 2;
			}
			th {
				font-weight: normal;
				text-align: left;
				width: 7em;
				padding-left: .5em;
			}
			td {
				padding: 0;
				address {
					font-style: normal;
				}
			}
		}
		.btn_t {
			a {
				text-decoration: none;
				text-align: center;
				.font-size(1.4);
				display: block;
				background: @light-grey;
				line-height: 2.2em;
				letter-spacing: .5em;
				text-indent: .5em;
				.transition-property(~"background,color");
				.transition(@transition-fast ease-in-out);
				&:after {
					margin-left: 1em;
					font-family: 'hybstemplate';
					content: '\e609';
				}
				&:hover {
					background: @inverse;
					color: @white;
				}
			}
		}
	}
} //.block