@import './turret.css';
@import './iconfont.css';
@import 'turret/definitions';
@import 'turret/utilities/mixins';
@import 'turret/utilities/utility-classes';
//webfont 読み込み
@import 'fonts/Roboto';
@import 'fonts/NotoSans';
@import 'fonts/font-awesome';

html {
	&.font-small {
		font-size: 10px;
	}
	&.font-middle {
		font-size: 12px;
	}
	&.font-large {
		font-size: 14px;
	}
}

body {
	.font-size(1.2);
}
a{
	text-decoration: none;
	outline:none;
	&:hover{
		text-decoration: underline;
	}
	&:focus{
		outline:none;
	}
}

.status {
	width: 100%;
	text-align: center;
	.text-light;
	.background-info;
}

//LAYOUT
#allbox{
	@media (min-width:960px){
		overflow: hidden;
	}
}
header,
#container {
	margin: 0 auto;
	width: 960px;
	#side {
		float: right;
	}
	&.main-right {
		#main {
			float: right;
		}
		#side {
			float: left;
		}
	}
}
.mainImg{
	width: 100%;
	overflow: hidden;
	text-align: center;
	.mainImgInner{
		display: inline-block;
	}
}

#main {
	width: 725px;
	padding: 0;
}

#side {
	width: 200px;
	padding: 0;
}

.toolbar {
	width: 960px;
	margin: 10px auto 10px;
	h1 {
		.font-size(1.2);
		line-height: 28px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 0;
	}
	.tools {
		padding-left: 0;
		.button {
			border-radius: 0;
		}
	}
}

.hybsSearch {
	background: @white;
	margin: 0 15px 0 0;
	width: 198px;
	border: 1px solid #cecece;
	input[name="search"] {
		border: none;
		height: 26px;
		padding: 0 10px;
	}
	button[type="submit"].button {
		padding: 4px 5px;
		height: 26px;
	}
	.icon-search:before {
		font-size: 18px;
	}
}

.fontChange {
	font-size: 0;
	margin-right: 10px;
	label {
		line-height: 28px;
		font-size: 12px;
		margin-right: 10px;
	}
	button {
		height: 28px;
		width: 45px;
		vertical-align: top;
		padding: 0;
		font-size: 12px;
		font-weight: 400;
		margin-right: 5px;
		border: none;
		background: #efefef;
		&.font-middle {
			font-size: 14px;
		}
		&.font-large {
			font-size: 16px;
		}
		&.current {
			color: @button-inverse-color;
			background: @button-inverse-background;
			border: @button-inverse-border;
			&:hover, &:focus {
				color: @button-inverse-hover-color;
				background: @button-inverse-hover-background;
				border: @button-inverse-hover-border;
			}
		}
	}
}

.hybsLogin {
	background: @white;
	a {
		.hide-text;
		.square(28px);
		vertical-align: top;
		padding: 0;
		border: 1px solid #cecece;
		.transition-property(~"background-color,border-color");
		background: url(../images/hybs_login.png) no-repeat 7px center;
		&.is--login{
			background: url(../images/hybs_login.png) no-repeat -22px center;
		}
		&:hover,
		&:active{
			background: url(../images/hybs_login.png) no-repeat -22px center;
			background-color: #c4c8d2;
		}
		&:focus{
			background: url(../images/hybs_login.png) no-repeat -22px center;
		}
	}
}

header {
	table tr {
		th, td {
			padding: 0;
		}
	}
	&#header .site_title{
		text-decoration: none;
		border-bottom:1px solid transparent;
		line-height: 1.5;
		.transition-property(~"border-bottom-color,color");
		&:hover{
			color:lighten(@link-color,15%);
			border-bottom:1px solid lighten(@link-color,50%);
			text-decoration: none;
		}
	}
}

#gmenu {
	ul {
		width: 960px;
		margin: 30px auto 30px;
		display: table;
		border-left: 1px solid #aaa;
		li {
			display: table-cell;
			width: 1%;
			border-right: 1px solid #aaa;
			a {
				text-decoration: none;
				display: inline-block;
				width: 100%;
				padding: 10px;
				line-height: 1.4em;
				text-align: center;
				vertical-align: middle;
				.font-size(1.5);
				white-space: nowrap;
				text-overflow: ellipsis;
				&:hover {
					color: #999;
				}
			}
		}
		&.overwidth li a{
			white-space: normal;
		}
	}
}

.mainImg {
	margin-bottom: 35px;
}

.breadcrumbs {
	margin-bottom: 35px;
	ol {
		width: 960px;
		margin: 0 auto;
		list-style: none;
		background: @light;
		font-size: 0;
		overflow: hidden;
		li {
			display: inline-block;
			.font-size(1.2);
			margin: 0;
			&:first-child a {
				padding-left: 1em;
			}
			>span {
				display: inline-block;
				padding-left: 2em;
			}
			a {
				text-decoration: none;
				line-height: 2.5em;
				padding-left: 2.5em;
				padding-right: 1em;
				display: block;
				background: darken(@light, 16%);
				.font-size(1.2);
				position: relative;
				.transition(background @transition-medium ease-in-out);
				&:after {
					z-index: 10;
					.arrow-right(1.5em, @white);
				}
				&:before {
					z-index: 20;
					.transition(border-color @transition-medium ease-in-out);
					.arrow-right(1.25em, darken(@light, 16%));
				}
				&:hover,
				&:focus{
					background: @inverse;
					color:@white;
					&:before{
						border-left-color:@inverse;
					}
				}
			}
		}
	}
}

.socialButton {
	.detailfooter &,
	.element_mailform form + &{
		.clearfix;
	}
	ul {
		list-style: none;
		.detailfooter &,
		.element_mailform form + &{
			float: right;
			li{
				display: inline-block;
				margin-left: 12px;
			}
		}
	}
	li {
		margin: 0 0 7px 0;
	}
	a {
		display: inline-block;
		vertical-align: top;
		height: 28px;
		font-size: 0;
		color: #fff;
		border-radius: 2px;
		&:visited {
			color: #fff;
		}
		&:hover {
			color: #fff;
			opacity: .8;
		}
		i {
			display: inline-block;
			line-height: 28px;
			text-align: center;
			.square(28px);
			.font-size(1.3);
			border-radius: 2px;
		}
		span {
			.font-size(1.4);
			display: inline-block;
			line-height: 28px;
			padding: 0 5px;
			font-weight: 700;
			font-family: 'Roboto';
		}
		&.sns-fb {
			background-color: #788cb8;
			i {
				background-color: #3f5b98;
			}
		}
		&.sns-tw {
			background-color: #8cd4e9;
			i {
				background-color: #5cc2e1;
			}
		}
		&.sns-gp {
			background-color: #d27970;
			i {
				background-color: #c03f35;
			}
		}
		&.sns-hb {
			background-color: #63a1d1;
			i {
				.font-size(1.5);
				background-color: #2577bf;
			}
		}
	}
}

footer {
	text-align: center;
	a.pagetop {
		display: block;
		background: @inverse;
		padding-top: 2px;
		color: @white;
		padding-bottom: 10px;
		text-decoration: none;
		.font-size(1.4);
		.transition(opacity @transition-fast ease-in-out);
		&:visited {
			color: @white;
		}
		&:hover {
			opacity: .8;
		}
		&:before {
			text-align: center;
			display: block;
			font-family: 'hybstemplate';
			content: "\e602";
			font-size: 7px;
			line-height: 19px;
		}
	}
	#footerNav {
		background: darken(@light-grey, 4.5%);
		padding-top: 30px;
		ul {
			li {
				display: inline-block;
				margin: 0;
				a {
					display: inline-block;
					padding: 5px 10px;
					white-space: nowrap;
					line-height: 1.5;
					vertical-align: middle;
					.font-size(1.4);
					&:hover {
						color: @grey;
					}
				}
			}
			&.overwidth li a{
				white-space: normal;
			}
		}
		.contentsNav {
			width: 960px;
			display: table;
			border-left: 1px solid #aaa;
			margin: 0 auto 30px;
			li {
				display: table-cell;
				width: 1%;
				border-right: 1px solid #aaa;
				a{
					text-decoration: none;
				}
			}
		}
		.subNav {
			padding-bottom: 30px;
			li {
				margin: 0 10px;
				a {
					text-decoration: none;
					.font-size(1.2);
					padding: 0 10px;
					&:before {
						font-family: 'hybstemplate';
						content: "\e609";
						margin-right: 10px;
					}
				}
			}
		}
	}
	.copyright {
		display: block;
		margin-bottom: 30px;
		a {
			text-decoration: none;
			letter-spacing: .1em;
			color: lighten(@body-color,20%);
			.font-size(1);
			cursor: default;
			&:visited {
				color: lighten(@body-color,20%);
			}
		}
	}
	.promotion{
		display: block;
		margin-bottom: 30px;
		a{
			color: lighten(@body-color,20%);
			text-decoration: none;
			letter-spacing: .1em;
			.font-size(1);
			cursor: default;
			&:visited {
				color: lighten(@body-color,20%);
			}
		}
	}
	.pv_switch {
		margin:0;
		a {
			font-size:3.5rem;
			text-align: center;
			display: block;
			line-height: 3.5em;
			text-decoration: none;
			background: #4b4b4b;
			color:@white;
			font-weight: bold;
			&:before{
				font-size:3.6rem;
				font-weight: normal;
				margin-right: .3em;
			}
			&:active,&:hover{
				opacity:.8;
			}
		}
	}

}

br.clear {
	clear: both;
	content: " ";
	display: table;
	*zoom: 1;
}

//*****************************************
//*	共通部(ブロック・一覧・詳細)
//*****************************************/
#main > .block,
#side > .block,
#main > .element_list{
	margin-bottom: 28px;
	&:empty{
		margin-bottom: 0;
	}
	//カテゴリ表記
	h4.catetitle,
	.block_title > h4,
	.catart .cat > li,
	.list_title1 h4{
		margin: 0 0 29px 0;
		a {
			text-decoration: none;
			display: block;
			padding:5px 9px;
			line-height: 1.4;
			border: 1px solid darken(@light-grey, 4.5%);
			border-width: 1px 0;
			background-color: @light;
			.transition(@transition-fast ease-in-out);
			.transition-property(~"background,color");
			&:hover {
				background-color: lighten(@grey, 4.5%);
				color: @white;
			}
		}
	}
	p {
		//文字組
		.font-size(1.2);
		&.catch,
		&.catchcp,
		&.catch strong {
			font-weight: 400;
			.font-size(1.3);
			color: @grey;
		}
		&.catch + p{ //本文
			margin-bottom: 0;
		}
		&.date:empty {
			margin-bottom: 0;
		}
		&.cart_list_price {
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 400;
			font-family: 'Roboto';
			.price2 {		//定価
				margin-top: .8em;
				display: block;
				color: @grey;
				text-decoration: line-through;
			}
			.price.price3,
			.price3{		//お問い合わせ下さい
				.font-size(1.2);
			}
			.price {		//販売価格
				.font-size(2);
			}
		}
	}
	h5 {
		//newマーク
		>.new, & +.new {
			.hide-text;
			&:after {
				display: inline-block;
				background: @red;
				color: @white;
				.font-size(.86);
				.size(4.55em, 2em);
				line-height: 2em;
				font-family: 'hybstemplate';
				content: "\e60a";
				text-align: center;
				vertical-align: top;
				margin: 0 -9px 0 2px;
				font-weight: bold;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		.catetitle {
			display: inline-block;
			margin: 0 0 0 3px;
			.font-size(1);
			color: @grey;
			a {
				color: @grey;
			}
		}
	}
	.wrapLink{	//記事ブロックを外包するリンク
		display: block;
		text-decoration: none;
		.transition(background @transition-fast ease-in-out);
		&:hover {
			background: @light;
			h5 > a{
				text-decoration: underline;
			}
		}
	}
	.element_freearea,
	.element .entry{
		width: 100%;
		overflow: hidden;
	}
	.artloop {	//artloopはメインカラムにしか無い
		.transition(background @transition-fast ease-in-out);
		width: 100%;
		overflow: hidden;
		h5 {
			.font-size(1.4);
			line-height: 1.4;
			margin-bottom: 0;
			font-weight: 600;
			&.titleOnly{
				font-weight: 400;
			}
			.catetitle {
				display: inline-block;
				margin-left: 3px;
				.font-size(1);
				color: @grey;
				a {
					color: @grey;
					&:hover {
						text-decoration: underline;
					}
				}
			}
			& + .entry{
				margin-top: 1em;
			}
		}
		&:hover {
			background: @light;
		}
		>table >tbody >tr >td {
			padding: 0;
			vertical-align: top;
			&:first-child {
				width: 1%;
				padding-right: 20px;
			}
		}
	}
}
#main > .element_detail{
	margin-bottom: 28px;
}
//lightbox
#lightbox-container-image-data-box{
	.box-sizing(content-box);
}



@import './_blocks';
@import './_contents';
//103_01_white-green用のカスタムスタイル
@import './_103_01_white-green';
