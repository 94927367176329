//カート詳細ページ
.entry > .entry_cart{
	.detail_box{
		.clearfix;
	}
	.detail_item{
		width: 340px;
		float: right;
		&.noThumb{
			width: 80%;
			float: none;
			margin: 0 auto;
		}
		.hs_cartDetailTable{
			width: 100%;
			th{
				white-space: nowrap;
				width: 1%;
				text-align: left;
				.font-size(1.4);
				font-weight: normal;
				color:@black;
			}
		}
		.salePrice{
			td{
				font-family: 'Roboto';
				.font-size(1.4);
				.priceTxt{
					.font-size(2.6);
					color:@black;
					line-height: 1;
					&:first-letter{
						.font-size(2);
						margin-right: .2em;
					}
				}
			}
		}
		.defaultPrice{
			td{
				text-decoration: line-through;
			}
		}
		.amount{
			input[name="num"]{
				display: inline-block;
				width:110px;
				padding:2px 6px;
				margin-right: 10px;
				height:30px;
			}
			.button{
				display: inline-block;
				.square(30px);
				padding: 0px;
				line-height: 30px;
				line-height: 3rem;
				text-decoration: none;
				.font-size(.7);
				background: @light-grey;
				.gradient(@white,10%,darken(@light-grey,2%),100%);
				color:@inverse;
				&:before{
					font-family:'hybstemplate';
				}
				&.add:before{
					content:'\e60e';
				}
				&.reduce:before{
					content:'\e60d';
				}
				&:hover{
					color:@white;
					background: @inverse;
				}
			}
		}
		.neederror{
			display: block;
			text-align: center;
			margin-bottom: .5em;
		}
		.detail_button{
			margin-bottom: 20px;
			button{
				width: 100%;
			}
			.cartinput{
				height:60px;
				background: @red;
				.font-size(1.8);
				font-weight: normal;
				color:@white;
				border:none;
				&:before{
					font-family: 'hybstemplate';
					content:'\e60c';
					margin-right: .5em;
				}
				&:hover{
					background: lighten(@red,10%);
				}
				&:disabled{cursor: pointer;}
			}
			.cartinquiry{
				background: @light;
				.font-size(1.3);
				font-weight: normal;
				padding: 0;
				line-height: 30px;
				height:30px;
				border-radius: 30px;
				&:hover{
					background: @grey;
					color:@white;
				}
			}
		}
		.detail_guidelink{
			ul{
				list-style: none;
				margin: 30px 0;
			}
			li{
				margin: 0;
			}
			a{
				.font-size(1.2);
				text-decoration: none;
				text-indent: .7em;
				display: block;
				border-bottom: 1px solid @light-grey;
				line-height: 2.5em;
				&:hover{
					background: @light;
				}
			}
		}
	}
	.detail_photobox{
		width: 350px;
		margin-bottom: 30px;
		ul{
			margin: 0;
		}
		li{
			width: 350px;
			margin: 0;
			text-align: center;
		}
		.detail_photo{
			display: inline-block;
//			max-height: 350px;
//			max-width: 350px;
		}
		.photobox_border{
			margin-bottom: 17px;
			position: relative;
			//max-height: 350px;
			overflow: hidden;
		}
		.bx-viewport{overflow: visible !important;}
		.zoom{
			visibility: hidden;
			display: block;
			top:0;
			left:0;
			position: absolute;
			.square(100%);
			text-decoration: none;
			color:@white;
			opacity:0;
			.transition(@transition-fast ease-in-out);
			&:before{
				.font-size(1.6);
				line-height: 50px;
				font-family: 'hybstemplate';
				content:'\e604';
				position: absolute;
				top:50%;
				left:50%;
				.square(50px);
				margin-top: -25px;
				margin-left: -25px;
				border-radius: 3px;
				background: @inverse;
				text-align: center;
			}
		}
		.controls{
			visibility: hidden;
			opacity:0;
			.transition(@transition-fast ease-in-out);
			.next,.prev{
				margin: 0;
			}
			a{
				display: block;
				width:25%;
				height:100%;
				position: absolute;
				top:0;
				text-decoration: none;
				&:before{
					font-family: 'hybstemplate';
					.font-size(4.6);
					margin-top: -35px;
					position: absolute;
					top: 50%;
				}
			}
			.next a{
				right:0;
				text-align: right;
			}
			.next a:before{
				content:'\e609';
				right: 0;
			}
			.prev a:before{
				content:'\e60f';
				left: 0;
			}
		}
		.photobox_border:hover{
			.zoom{
				visibility: visible;
				opacity:.5;
			}
			.controls{
				visibility: visible;
				opacity: 1;
				a{
					opacity: .3;
					&:hover{
						opacity: 1;
					}
				}
			}
		}
		.thumbnails{
			display: table;
			width: 100%;
			margin:0 -10px;
			li{
				display: table-cell;
				padding:0 0 0 10px;
				text-align: center;
				font-size:0;
				position: relative;
				z-index:1;
			}
			a{
				display: inline-block;
				&:hover img{
					opacity: .8;
				}
			}
			a.active{
				box-shadow: 0 0 0 5px fade(@light,60%) inset;
			}
			img{
				position: relative;
				z-index:-1;
				display: block;
			}
		}
	}
}
/* IE8 */
body {
	.entry > .entry_cart .detail_photobox {
		.detail_photo{
			height: 350px;
			width: 350px;
		}
		.photobox_border{height: 350px;}
	}
}
/* IE9～, safari, Chrome, Firefox, Opera... */
html:root body {
	.entry > .entry_cart .detail_photobox {
		.detail_photo{
			height:auto;
			width: auto;
			max-height: 350px;
			max-width: 350px;
		}
		.photobox_border{
			height:auto;
			max-height: 350px;
		}
	}
}

//商品について問い合わせる
#main > .element_cart_inquiry{
	h4{
		.font-size(1.8);
		line-height: 3em;
		border-bottom: 1px solid darken(@light-grey,21.5%);
	}
	.entry_cart{
		margin-bottom: 50px;
		.needs{
			color:@error;
		}
		.formbox{
			margin-bottom: 50px;
			.details{
				tr{
					border:1px solid @light-grey;
					border-width:0 0 1px;
				}
				th{
					padding:5px 10px;
					text-align: center;
				}
			}
			.details_input{
				th,
				td{
					border-top: 1px solid @light-grey;
					border-bottom: 1px solid @light-grey;
					vertical-align: middle;
				}
				th{
					text-align: right;
					width: 1%;
					white-space: nowrap;
					padding-right: 10px;
				}
			}
			textarea{
				width: 100% !important;
				border-color:@light-grey !important;
			}
		}
		.cart_title{
			.font-size(1.6);
			margin-bottom: 25px;
			&:before{
				font-family: 'hybstemplate';
				content:'\e609';
				margin-right: .5em;
			}
		}
		blockquote{
			padding:20px 50px;
			p{
				color:@body-color;
				.font-size(1.4);
			}
		}
	}
}

//ショッピングガイド用
.element_shopguide{
	.entry{
		margin-bottom: 50px;
	}
	.advtable{
		margin-bottom: 50px;
		tr{
			border-top: 1px solid @light-grey;
		}
		th{
			white-space: nowrap;
			background-color: transparent;
			padding:10px;
			.font-size(1.4);
			line-height: 1.5;
			font-weight: normal;
			color:@black;
			text-align: right;
		}
		td{
			width: 100%;
			padding-left: 30px;
			color:lighten(@dark-grey, 15%);
		}
		address{
			font-style: normal;
		}
	}
}
//買い物カゴ
.shopping_progress{
	margin-bottom: 50px;
	.cart_title h4{
		.font-size(1.8);
	}
	.stepprogre{
		width: 100%;
		margin: 0 0 30px;
		list-style: none;
		overflow: hidden;
		display: table;
		border-radius: 3px;
		border:1px solid darken(@light-grey,10%);
		>br{
			display: none;
		}
		li {
			display: table-cell;
			.font-size(1.2);
			margin: 0;
			text-align: center;
			&:first-child span {
				padding-left: 1em;
			}
			>span {
				color:@black;
				display: inline-block;
				text-decoration: none;
				line-height: 2.5em;
				padding-left: 2em;
				padding-right: 1em;
				display: block;
				background: @light-grey;
				.font-size(1.2);
				position: relative;
				&:after {
					z-index: 10;
					.arrow-right(1.5em, @white);
				}
				&:before {
					z-index: 20;
					.arrow-right(1.25em, @light-grey);
				}
			}
			&.done span{
				color:@grey;
			}
			&.flow6>span:before,
			&.flow6>span:after{
				border:0px;
			}
		}
		&.current1 .flow1>span{
			background: @white;
			&:before{
				border-left-color: @white;
			}
		}
		&.current2 .flow2>span{
			background: @white;
			&:before{
				border-left-color: @white;
			}
		}
		&.current3 .flow3>span{
			background: @white;
			&:before{
				border-left-color: @white;
			}
		}
		&.current4 .flow4>span{
			background: @white;
			&:before{
				border-left-color: @white;
			}
		}
		&.current5 .flow5>span{
			background: @white;
			&:before{
				border-left-color: @white;
			}
		}
		&.current6 .flow6>span{
			background: @white;
			&:before{
				border-left-color: @white;
			}
		}
	}
	input[type="button"],
	input[type="submit"]{
		.font-size(1.2);
		border:1px solid @light-grey;
		border-radius: 3px;
		&:hover{
			opacity:.8;
		}
	}
	.cart_title{
		font-weight: bold;
	}
	.credit_available{
		padding: .3em 0 0 1em;
		margin-bottom: 30px;
		img{
			width: 30px;
			vertical-align: middle;
		}
	}
	&.step4{
		.details_input{
			th,
			td{
				border-top: 1px solid @light-grey;
				border-bottom: 1px solid @light-grey;
				vertical-align: middle;
			}
			th{
				padding:10px 25px 10px 10px;
			}
			label{
				display: inline-block;
			}
		}
	}
	blockquote{
		padding:20px 50px;
		p{
			color:@body-color;
			.font-size(1.4);
		}
	}
}
.shopping_progress,
#main > .element_cart_inquiry{
	.formbox{
		.details{
			width: 100%;
			margin-bottom: 10px;
			tr{
				border-bottom:1px solid @light-grey;
			}
			th{
				text-align: center;
				padding:10px;
			}
		}
		.details_totalprise{
			table:first-child{
				width: 100%;
				text-align: right;
				td{
					.font-size(1.2);
					padding:5px 10px;
				}
			}
			.price{
			}
			.priceTotal{
				font-family: 'Roboto';
				.font-size(2.6);
				margin: 0 .5em;
			}
		}
		.details_input{
			width: 100%;
			margin-bottom: 50px;
			.needs{
				color:@error;
				margin-left: .5em;
			}
			.neederror{
				color:@error;
				.font-size(1);
			}
			th,
			td{
				border-top: 1px solid @light-grey;
				border-bottom: 1px solid @light-grey;
				vertical-align: middle;
			}
			th{
				white-space: nowrap;
				width: 1%;
				padding: 10px 25px 10px 10px;
				text-align: right;
				.font-size(1.4);
				color:@black;
				font-weight: normal;
			}
			td{padding-top: 10px;}
			label{
				.font-size(1.4);
				margin-right: 30px;
			}
			input,
			select{
				width: auto;
				display: inline-block;
			}
			select{margin-bottom: 5px;}
			textarea{
				resize: vertical;
			}
		}
		.details_input.confirm{
			th{
				padding: 10px 25px 10px 10px;
				color:@grey;
				vertical-align: middle;
			}
			td{
				padding-top: 10px;
				.font-size(1.6);
				background: @light;
				border:3px solid @white;
			}
		}
	}
	table.navigateBtn{
		width: auto;
		margin: 0px auto;
		td{
			padding: 10px 5px;
		}
		input[type="submit"],
		input[type="button"]{
			.font-size(1.6);
			margin: 0 5px;
			border-radius: 3px;
			padding:7px 20px;
			color:@white;
			border:1px solid @light-grey;
			background:@inverse;
			&:hover{
				opacity: .8;
			}
			&.orderbuttom{
				background: @red;
				.font-size(2);
			}
			&.next{
				background: @red;
			}
		}
	}
}
body[class*="info_delivery"]{
	.shopping_progress .formbox .details_input td {
		padding-top: 25px;
	}
}
#main > .element_detail{
	.attention{
		margin-bottom: 20px;
		padding-left: 15px;
		p{
			.font-size(1.2);
			margin:0 0 5px 0;
		}
	}
}